<template>
  <div class="line-item-v2">
    <div class="d-flex mb-2 align-center justify-space-between">
      <div class="d-flex">
        <v-autocomplete
          v-if="relatedType != 7"
          hide-details
          v-model.trim="module_type"
          :items="modulesType"
          clearable
          v-on:click:clear="module_type = null"
          style="max-width: 250px !important"
          dense
          flat
          filled
          placeholder="Item type"
          item-color="cyan"
          class="mt-1 mr-1 custom-boder-color"
          color="cyan"
          solo
          v-on:change="open_dialog($event)"
          item-value="value"
          item-text="text"
        >
        </v-autocomplete>
        <v-btn-toggle color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="product"
            v-on:click="open_dialog('product')"
            v-if="relatedType != 7 && false"
          >
            Product
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="service"
            v-on:click="open_dialog('service')"
            v-if="relatedType != 7 && false"
          >
            Service
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="equipment"
            v-if="relatedType == 7"
            v-on:click="open_dialog('equipment')"
          >
            Customer Asset
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="tools"
            v-on:click="open_dialog('tools')"
            v-if="relatedType != 7 && false"
          >
            Equipment/Tools
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            v-on:click="add_header()"
            style="height: 32px !important"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_other()"
            style="height: 32px !important"
            v-if="relatedType != 7"
          >
            Other
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex align-center">
        <v-checkbox
          v-if="!isEnquiry && !isQuotation && !isPreventive"
          class="my-0 py-0"
          v-model="show_price"
          v-on:change="emit_values()"
          color="cyan white--text"
          label="Show Price To Engineer"
        />
        <div
          class="align-center ml-4 pl-4 custom-border-left"
          style="margin-top: -28px"
        >
          <div class="mr-2">
            <label for="discount-level" class="mb-0 font-weight-600 d-flex"
              >Discount Level</label
            >
          </div>
          <div>
            <v-autocomplete
              :disabled="relatedDetail && relatedDetail.id > 0"
              hide-details
              v-model.trim="discount_level"
              :items="transactions"
              style="max-width: 250px !important"
              dense
              flat
              filled
              placeholder="Discount Level"
              item-color="cyan"
              class="mt-1 mr-1 custom-boder-color"
              color="cyan"
              solo
              v-on:change="calculate_total()"
              item-value="value"
              item-text="text"
            >
            </v-autocomplete>
            <v-radio-group
              :disabled="relatedDetail && relatedDetail.id > 0"
              v-on:change="calculate_total()"
              v-if="false"
              id="discount-level"
              v-model="discount_level"
              row
              hide-details
              class="px-0 py-0"
            >
              <v-radio
                label="Line Item"
                value="line_item"
                color="cyan"
              ></v-radio>
              <v-radio
                label="Transaction"
                value="transaction"
                color="cyan"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-grey-border">
      <div class="d-flex grey lighten-4">
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          style="width: 5%"
        >
          #
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-left"
          :style="get_col_style('product')"
        >
          <template v-if="relatedType == 7">Customer Asset</template>
          <template v-else
            >Part/Product/Service/ Customer Asset/ Tools</template
          >
        </div>
        <div
          v-if="false"
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Available Quantity
        </div>
        <div
          v-if="false"
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Sub-Location
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Selling/Unit Cost
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Quantity
        </div>
        <div
          v-if="discount_level == 'line_item'"
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 15%"
        >
          Discount
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Total
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700"
          style="width: 5%"
        ></div>
      </div>
      <Draggable
        tag="ul"
        v-model="selected_line_items"
        class="line-item-drag-group mb-0"
        draggable=".line-item-drag"
        handle=".line-item-drag-icon"
        ghost-class="line-item-ghost"
        :key="`line-item-img-${dialog_key}`"
        animation="200"
        group="line-item-drag"
        v-on:start="drag = true"
        v-on:end="drag = false"
        v-on:change="emit_values()"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <li
            v-for="(row, index) in selected_line_items"
            class="line-item-drag"
            :class="{
              'alternate-line-item-row':
                selected_line_items[index + 1]?.is_child,
            }"
            :key="`line-item-drag-${index}`"
          >
            <div
              :id="`line-item-drag-${index}`"
              class="d-flex my-0"
              :class="{ 'item-is-child': row.is_child }"
            >
              <div class="font-weight-600 text-center" style="width: 5%">
                <v-icon
                  v-if="!row.is_child"
                  class="line-item-drag-icon cursor-move"
                  color="color-custom-blue"
                  >mdi-drag</v-icon
                >
              </div>
              <div
                v-if="row.is_child"
                class="font-weight-600 text-center"
                style="width: 5%"
              >
                <v-icon
                  class="line-item-drag-icon cursor-move"
                  color="color-custom-blue"
                  >mdi-drag</v-icon
                >
              </div>
              <div
                v-if="row.type == 'header'"
                class="text-left px-2"
                style="width: 90%"
              >
                <v-text-field
                  v-model="row.product"
                  class="line-item-header-text px-0"
                  placeholder="Header"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="changeName(index, row.product)"
                  color="cyan"
                >
                  <template v-slot:append>
                    <v-chip
                      color="cyan white--text"
                      label
                      class="lip-type text-uppercase font-weight-600 header"
                    >
                      Header
                    </v-chip>
                  </template>
                </v-text-field>
              </div>
              <div
                v-else
                class="text-left px-2"
                :style="get_col_style('product', row.is_child)"
              >
                <div class="d-flex">
                  <v-avatar size="32" v-if="row.product_type != 'other'">
                    <img
                      v-if="row?.image?.url"
                      :src="row?.image?.url"
                      :alt="row?.image?.name"
                    />
                    <img v-else :src="$defaultImage" />
                  </v-avatar>
                  <div style="width: 100%" class="ml-2">
                    <v-expansion-panels class="cepfli">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <v-text-field
                            v-model="row.product"
                            class="line-item-header-text px-0"
                            placeholder="Product/Service/Customer Asset/Equipment  & Tools"
                            dense
                            filled
                            solo
                            v-on:change="changeName(index, row.product)"
                            flat
                            color="cyan"
                          >
                            <template v-slot:append>
                              <v-chip
                                color="cyan white--text"
                                label
                                class="lip-type text-uppercase font-weight-600"
                                :class="{
                                  product: row.product_type == 'goods',
                                  service: row.product_type == 'service',
                                  parts: row.product_type == 'parts',
                                  equipment: row.product_type == 'equipment',
                                  tools: row.product_type == 'tools',
                                  other: row.product_type == 'other',
                                }"
                              >
                                <template v-if="row.type == 'tools'">
                                  Tools</template
                                >
                                <template v-else-if="row.type == 'equipment'">
                                  Customer Asset</template
                                >
                                <template v-else> {{ row.type }}</template>
                              </v-chip>
                            </template>
                          </v-text-field>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-0">
                          <v-textarea
                            class="px-0 mb-0"
                            v-model="row.description"
                            placeholder="Description"
                            dense
                            filled
                            solo
                            flat
                            rows="2"
                            auto-grow
                            color="cyan"
                            v-on:change="changeDes(index, row.description)"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <div
                      class="d-flex ml-6"
                      v-if="row && row.cimages && row.cimages.length && false"
                    >
                      <div
                        style="position: relative"
                        v-for="(nimg, nmlindex) in row.cimages"
                        :key="`new-image-${nmlindex}`"
                      >
                        <!-- {{ nimg }} -->
                        <v-img
                          :aspect-ratio="1"
                          height="100px"
                          width="100px"
                          class="mr-4 my-4"
                          :src="nimg"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey darken-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-btn
                          style="position: absolute; top: 0; right: 0"
                          icon
                          color="red darken-1"
                          v-on:click="removeImage(nmlindex, index)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex ml-0 mt-0">
                      <v-chip
                        v-if="row.barcode"
                        label
                        color="chip-custom-blue"
                        outlined
                        small
                        class="text-white p-3 mt-0 mb-1 mr-2"
                        style="font-weight: 700"
                        v-on:click="routeToDetail(row)"
                        >{{ row.barcode }}</v-chip
                      >
                      <v-checkbox
                        v-if="row.type == 'product'"
                        inset
                        color="cyan"
                        label="Add as customer asset"
                        dense
                        class="pt-0 mt-0 ml-2"
                        v-model="row.is_equipment"
                        hide-details
                        :false-value="0"
                        :true-value="1"
                        v-on:change="changeEquipment(index, row.is_equipment)"
                      >
                      </v-checkbox>
                      <template
                        v-if="
                          (row.product_type == 'equipment' ||
                            row.product_type == 'tools' ||
                            row.product_type == 'asset') &&
                          row &&
                          row.has_warranty &&
                          row.has_warranty == 1 &&
                          row.warranty_status > 0
                        "
                      >
                        <v-chip
                          :color="getWarrantyStatusColor(row.warranty_status)"
                          small
                          text-color="white"
                          class="text-white p-3 mt-0 mb-1 mr-2"
                        >
                          Warranty
                        </v-chip>
                      </template>
                      <template
                        v-if="
                          row.product_type == 'equipment' &&
                          row &&
                          row.preventive_id &&
                          row.preventive_barcode &&
                          row.preventive_id > 0 &&
                          showModules.preventive_maintanance
                        "
                      >
                        <v-chip
                          :color="row?.is_consumed ? 'red' : 'lightgray'"
                          class="p-3 mt-0 mb-1 ml-0 mr-2"
                          small
                          text-color=""
                          outlined
                          v-on:click.prevent.stop="
                            routeToDetailLink(
                              row.preventive_id,
                              'admin.preventive-maintanance.detail'
                            )
                          "
                        >
                          {{ row.preventive_barcode }}
                        </v-chip>
                      </template>
                      <template
                        v-if="
                          row.product_type == 'equipment' &&
                          row &&
                          row.preventive_id &&
                          row.preventive_barcode &&
                          row.preventive_id > 0 &&
                          showModules.location
                        "
                      >
                        <v-chip
                          :color="row?.is_consumed ? 'red' : 'lightgray'"
                          class="p-3 mt-0 mb-1 ml-0 mr-2"
                          small
                          text-color=""
                          outlined
                          v-on:click.prevent.stop="
                            routeToDetailLink(
                              row.preventive_id,
                              'admin.preventive-maintanance.detail'
                            )
                          "
                        >
                          {{ row.location }}
                        </v-chip>
                      </template>
                      <!--   <template v-if="row.equipment_warranty != 1">
                          <template  v-if="row.has_warranty == 1 && row.type == 'product' && row.is_equipment == 1">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mx-2 custom-bold-button white--text mt-2"
                                  color="cyan"
                                  tile
                                  style="height: 20px !important;"
                                  v-on:click="OpenchangeWarrnty(index,row)"
                                >
                                  Warranty
                                </v-btn>
                              </template>
                              <span>Equipment Warranty</span>
                            </v-tooltip>`
                          </template>
                        </template>
                        <template  v-if="row.has_warranty == 1 && row.type == 'product' && row.is_equipment == 1 && row.equipment_warranty == 1">
                             <v-chip label small text-color="white" color="red" class="mx-2 mt-2"> Warranty Created </v-chip>
                        </template>
                         <em v-if="row.has_warranty == 0 && row.type == 'product' && row.is_equipment == 1 " class="mx-2 mt-2 font-weight-600"> (no product warranty) </em> -->
                      <v-btn
                        v-if="false"
                        :disabled="row.product_type == 'header'"
                        v-on:click="selectImage(index)"
                        small
                        depressed
                        color="cyan white--text"
                        class="mt-0 ml-4 custom-bold-button white--text"
                      >
                        <v-icon>mdi-cloud-upload-outline</v-icon> Upload Image
                      </v-btn>
                      <v-spacer></v-spacer>

                      <div
                        class="mt-0"
                        v-if="
                          row.product_type == 'equipment' &&
                          row.preventive_id &&
                          row.preventive_id > 0 &&
                          row.is_consumed > 0 &&
                          showModules.preventive_maintanance
                        "
                      >
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="cyan"
                              v-on:click="openSchedule(row.preventive_id)"
                              >mdi-information-outline</v-icon
                            >
                          </template>
                          <span>PM Schedules</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div
                      v-if="
                        row.product_type == 'equipment' &&
                        row &&
                        row?.preventive_id > 0 &&
                        row.is_consumed == 0 &&
                        relatedType == 3 &&
                        pmData &&
                        showModules.preventive_maintanance
                      "
                      style="color: red"
                      class="font-weight-600 d-flex align-center ml-5"
                    >
                      This equipment ({{ row.barcode }}), the PM ({{
                        row.preventive_barcode
                      }}) won't be consumed
                    </div>
                    <div
                      v-if="row.has_property"
                      class="font-weight-600 d-flex align-center ml-5"
                    >
                      <!-- <template
                                            v-if="row.type == 'equipment' && row && row.serial_no"
                                            >
                                            <v-chip
                                                label
                                                color="chip-custom-blue"
                                                outlined
                                                class="text-white p-3 mr-2"
                                                style="font-weight: 700; margin-top: -6px"
                                                >{{ row.serial_no }}</v-chip
                                            >
                                            </template> -->
                      <!--   <template v-if="row?.property?.parent?.name">
                                            <v-chip>{{ row.property.parent.name }}</v-chip>
                                            <v-icon class="px-1">mdi-chevron-right</v-icon>
                                            </template>
                                            <template v-if="row?.property?.child?.name">
                                            <v-chip>{{ row.property.child.name }}</v-chip>
                                            <v-icon class="px-1">mdi-chevron-right</v-icon>
                                            </template>
                                            <template v-if="row?.property?.type == 'child-child-'">
                                            <v-chip style="padding: 20px !important">
                                                <div>
                                                <div>
                                                    {{ row?.property?.property_name }}
                                                </div>
                    
                                                <div>{{ row.property.name }}</div>
                                                </div>
                                            </v-chip>
                                            </template>
                                            <template v-if="row?.property?.type == 'child-'">
                                            <v-chip style="padding: 20px !important">
                                                <div>
                                                <div>
                                                    {{ row?.property?.property_name }}
                                                </div>
                    
                                                <div>{{ row.property.name }}</div>
                                                </div>
                                            </v-chip>
                                            </template>
                                            <template v-if="row?.property?.type == 'parent-'">
                                            <v-chip
                                                style="padding: 20px !important"
                                                v-if="row?.property?.property_name"
                                            >
                                                <div>
                                                <div>
                                                    {{ row?.property?.property_name }}
                                                </div>
                    
                                                <div>{{ row.property.name }}</div>
                                                </div>
                                            </v-chip>
                                            </template> -->
                      <v-btn
                        v-if="false && row?.property?.id"
                        v-on:click="remove_property(index)"
                        icon
                        rounded
                        color="red lighten-1"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!(row.type == 'header') && false"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Available Quantity"
                  dense
                  filled
                  solo
                  type="number"
                  v-model="row.initial_stock"
                  flat
                  color="cyan"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, row.initial_stock)"
                />
              </div>
              <div
                v-if="!(row.type == 'header') && false"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Sub-Location"
                  dense
                  filled
                  solo
                  type="text"
                  v-model="row.location"
                  flat
                  color="cyan"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, row.location)"
                />
              </div>
              <div
                v-if="!(row.type == 'header')"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Selling/Unit Cost"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  v-model="row.rate"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, row.rate)"
                  color="cyan"
                />
              </div>
              <div
                v-if="!(row.type == 'header')"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Quantity"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  :readonly="
                    row.type == 'equipment' ||
                    row.type == 'tools' ||
                    row.type == 'service'
                  "
                  v-model="row.quantity"
                  :prepend="row.uom"
                  v-on:keyup="checkAvailableQuantity(row), calculate_total()"
                  v-on:keypress="isNumber($event)"
                  color="cyan"
                />
                <!-- <v-btn
                  style="height: 20px !important"
                  v-if="
                    row.product_type == 'goods' ||
                    (row.product_type == 'parts')
                  "
                  class="custom-bold-button white--text"
                  v-on:click="optionSerial(row, index)"
                  color="cyan"
                >
                  Select Serial
                  <template v-if="row?.serialize_number?.length > 0">
                    ({{ row?.serialize_number?.length }})</template
                  >
                  <template v-else>
                    ({{ row?.serialNumber?.length ?? 0 }})</template
                  >
                </v-btn> -->
              </div>
              <div
                v-if="discount_level == 'line_item' && !(row.type == 'header')"
                class="text-center px-2"
                style="width: 15%"
              >
                <div class="d-flex">
                  <v-text-field
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    filled
                    solo
                    flat
                    type="number"
                    v-model="row.discount_value"
                    v-on:keyup="calculate_total()"
                    v-on:keypress="limitDecimal($event, row.discount_value)"
                    color="cyan"
                    style="width: 50%; min-width: unset"
                  />
                  <v-select
                    :items="discount_type_list"
                    v-model="row.discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    v-on:change="calculate_total()"
                    color="cyan"
                  />
                </div>
              </div>
              <div
                v-if="!(row.type == 'header')"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Total"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  v-model="row.total"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, row.total)"
                  color="cyan"
                />
              </div>
              <div class="font-weight-600 text-right" style="width: 80px">
                <template v-if="!row.is_child && relatedType != 7">
                  <v-menu v-if="row.has_child" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="blue darken-4"
                      >
                        <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="py-0" min-width="150px">
                      <!--  <v-list-item
                          v-if="row.type == 'equipment'"
                          link
                          class="border-bottom"
                          v-on:click="add_child(index, 'property')"
                        >
                          <v-list-item-title
                            v-if="row?.property?.id"
                            class="font-weight-600"
                          >
                            Manage Site Location</v-list-item-title
                          >
                          <v-list-item-title v-else class="font-weight-600">
                            Add Site Location</v-list-item-title
                          >
                        </v-list-item> -->
                      <v-list-item
                        v-if="!row.is_child"
                        link
                        class="border-bottom"
                        v-on:click="add_child(index, 'equipment')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Customer Asset</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="!row.is_child && relatedType != 7"
                        link
                        class="border-bottom"
                        v-on:click="add_child(index, 'tools')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Tools</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="!row.is_child && relatedType != 7"
                        link
                        class="border-bottom"
                        v-on:click="add_child(index, 'product')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Product</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="!row.is_child && relatedType != 7"
                        link
                        class="border-bottom"
                        v-on:click="add_child(index, 'parts')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Parts</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="!row.is_child && relatedType != 7"
                        link
                        v-on:click="add_child(index, 'service')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Service</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  v-on:click="remove_row(index, row)"
                  icon
                  color="red lighten-1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </li>
        </transition-group>
      </Draggable>
      <template v-if="!selected_line_items.length">
        <div class="custom-border-top p-3">
          <p
            class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
          >
            <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>
            Oops... Nothing Found.
          </p>
        </div>
      </template>
    </div>
    <div class="d-flex mt-2">
      <!-- <v-btn-toggle v-model="product_type" color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="product"
            v-on:click="open_dialog('product')"
          >
            Product
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="service"
            v-on:click="open_dialog('service')"
          >
            Service
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="equipment"
            v-on:click="open_dialog('equipment')"
          >
            Equipment
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            v-on:click="add_header()"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_other()"
          >
            Other
          </v-btn>
        </v-btn-toggle> -->
      <div class="d-flex">
        <v-autocomplete
          v-if="relatedType != 7"
          hide-details
          v-model.trim="module_type"
          :items="modulesType"
          clearable
          v-on:click:clear="module_type = null"
          style="max-width: 250px !important"
          dense
          flat
          filled
          placeholder="Item type"
          item-color="cyan"
          color="cyan"
          solo
          v-on:change="open_dialog($event)"
          item-value="value"
          class="mt-1 mr-1 custom-boder-color"
          item-text="text"
        >
        </v-autocomplete>
        <v-btn-toggle color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="product"
            v-on:click="open_dialog('product')"
            v-if="relatedType != 7 && false"
          >
            Product
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="service"
            v-on:click="open_dialog('service')"
            v-if="relatedType != 7 && false"
          >
            Service
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="equipment"
            v-if="relatedType == 7"
            v-on:click="open_dialog('equipment')"
          >
            Customer Asset
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="tools"
            v-on:click="open_dialog('tools')"
            v-if="relatedType != 7 && false"
          >
            Tools
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            v-on:click="add_header()"
            style="height: 32px !important"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_other()"
            style="height: 32px !important"
            v-if="relatedType != 7"
          >
            Other
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div>
      <table width="100%" style="table-layout: fixed">
        <tbody>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Sub Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(subtotal) }}
            </td>
          </tr>
          <tr v-if="discount_level == 'transaction'">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Discount
            </td>
            <td width="15%" valign="middle" align="center">
              <div class="d-flex">
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Discount"
                  dense
                  filled
                  solo
                  flat
                  v-model="discount_value"
                  v-on:keyup="calculate_total(), checkData()"
                  v-on:keypress="
                    limitDecimal($event, discount_value), checkData()
                  "
                  v-on:change="checkData()"
                  color="cyan"
                />

                <v-select
                  :items="discount_type_list"
                  v-model="discount_type"
                  hide-details
                  item-text="type"
                  item-value="value"
                  item-color="cyan"
                  class="line-item-header-text discount-type text-h6 px-0"
                  placeholder="Type"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="calculate_total()"
                  color="cyan"
                />
              </div>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(discount) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Tax [GST {{ gst_value }}%]
            </td>
            <td width="15%" valign="middle" align="center">
              <v-checkbox
                v-on:change="calculate_total()"
                v-model="tax_active"
                class="my-0 py-0 px-0 mx-0"
                color="cyan"
                :label="`Tax Amount: ${formatMoney(gst_amount)}`"
              />
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(gst) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Adjustment
            </td>
            <td width="15%" valign="middle" align="center">
              <v-text-field
                hide-details
                class="line-item-header-text px-0 line-item-adjustment"
                placeholder="Adjustment"
                dense
                filled
                solo
                flat
                v-model="adjustment"
                v-on:keyup="calculate_total()"
                v-on:keypress="limitDecimal($event, adjustment)"
                color="cyan"
              >
                <template v-slot:append>
                  <v-btn depressed v-on:click="auto_adjust()">Auto</v-btn>
                </template>
              </v-text-field>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(adjustment) }}
            </td>
          </tr>
          <tr class="font-size-19">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Grand Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(total) }}
            </td>
          </tr>
          <tr class="font-size-19" v-if="show_collected">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
              style="color: red"
            >
              Amount to be collected
            </td>
            <td width="15%" valign="middle" align="center">
              <v-text-field
                class="line-item-header-text px-0"
                placeholder="Collected Amount"
                dense
                filled
                solo
                flat
                type="number"
                v-model="collected_amount"
                v-on:keypress="limitDecimal($event, collected_amount)"
                color="cyan"
              />
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
    <LineItemDialog
      :key="`line-item-dialog-${dialog_key}`"
      :product-type="product_type"
      :isType="relatedType"
      :product-dialog="product_dialog"
      :module-type="relatedType"
      v-on:update-values="update_line_item($event)"
      v-on:close="product_dialog = false"
    />
    <ManageWarranty
      :dialog-status="addWarrantyDialog"
      v-on:close:dialog="addWarrantyDialog = false"
      v-on:warrnty:data="warrantyData($event)"
    ></ManageWarranty>
    <AddressSelect
      line-item
      :key="`address-select-dialog-${dialog_key}`"
      :dialog="property_dialog"
      v-on:close="property_dialog = false"
      type="property"
      label="Service Location"
      v-on:update="update_child_property($event)"
    />
    <!-- Upload Image :: begin -->
    <div class="d-none">
      <v-file-input
        :ref="`line-item-image`"
        v-on:change="updateProfileImage"
        multiple
        accept="image/png, image/jpeg, image/jpg"
      ></v-file-input>
    </div>
    <PMSchedule
      v-if="scheduleDialog"
      :parent-id="pmId"
      :dialogStatus="scheduleDialog"
      v-on:close:dialog="scheduleDialog = false"
    >
    </PMSchedule>
    <!-- :local-entity="localEntity" -->
    <SerialNumber
      v-if="seleceDialog"
      :transaction-detail="transactionDetail"
      :parent-index="numberIndex"
      :dialogStatus="seleceDialog"
      :serial-number="numbersArr"
      v-on:close:dialog="seleceDialog = false"
      v-on:update:item="savedata"
    >
    </SerialNumber>
    <!-- Upload Image :: end -->
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import Draggable from "vuedraggable";
import AddressSelect from "@/view/components/LineItemAddressSelect.vue";
import LineItemDialog from "@/view/components/LineItemDialog.vue";
import AppConfiguration from "@/core/config/app.config.js";
import PMSchedule from "@/view/pages/preventive-maintanance/PMScheduleView.vue";
import SerialNumber from "@/view/pages/partials/Serial-Number-Select.vue";
import {
  SET_VISIT_LINE_ITEM,
  SET_VISIT_CALCULATION,
} from "@/core/services/store/visit.module";
import {
  UPDATE_SCHEDULE_LIST,
  UPDATE_SCHEDULE_LIST_QUOTE,
  UPDATE_SCHEDULE_STATE,
} from "@/core/services/store/invoice.module";
import { toBtxNumber, toBtxFixed } from "@/core/services/common.service";
import CommonMixin from "@/core/plugins/common-mixin.js";
import { find } from "lodash";
import { ErrorEventBus } from "@/core/lib/message.lib";
import { v4 as uuidv4 } from "uuid";
import ManageWarranty from "@/view/pages/partials/Create-Warranty-Intenal.vue";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "line-item-v1",
  mixins: [CommonMixin],
  data() {
    return {
      drag: false,
      pmData: [],
      module_type: null,
      transactionDetail: {},
      numbersArr: [],
      seleceDialog: false,
      numberIndex: 0,
      modulesType: [
        {
          value: "parts",
          text: "Part",
          show: false,
        },
        {
          value: "goods",
          text: "Product",
          show: false,
        },
        {
          value: "service",
          text: "Service",
          show: false,
        },
        {
          value: "equipment",
          text: "Customer Asset",
          show: false,
        },
        {
          value: "tools",
          text: "Tool",
          show: false,
        },
      ],
      dialog_key: Number(new Date()),
      product_type: null,
      product_dialog: false,
      child_index: null,
      addWarrantyDialog: false,
      append_child: false,
      warrantyObject: {},
      property_dialog: false,
      tax_active: false,
      do_update: 0,
      show_price: false,
      transactions: [
        {
          text: "Transaction",
          value: "transaction",
        },
        {
          text: "Line Item",
          value: "line_item",
        },
      ],
      discount_level: "transaction",
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ],
      imageSrc: [],
      selected_line_items: [],
      currentEntityDb: {},
      subtotal: 0,
      collected_amount: 0,
      discount: 0,
      scheduleDialog: false,
      pmId: 0,
      gst: 0,
      gst_amount: 0,
      gst_value: 9,
      adjustment: 0,
      total_non_text_amount: 0,
      total: 0,
      customIndex: 0,
      discount_value: null,
      discount_type: 1,
      /*   localEntity: [], */
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      show_collected: false,
    };
  },
  props: {
    relatedDetail: {
      type: Object,
      default: null,
    },
    dbLineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    dbEquipments: {
      type: Array,
      default() {
        return [];
      },
    },
    entityId: {
      type: Number,
      default: 0,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
    isCollected: {
      type: Boolean,
      default: false,
    },
    relatedType: {
      type: Number,
      default: 3,
    },
    isEnquiry: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isPreventive: {
      type: Boolean,
      default: false,
    },
    itemSet: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    relatedDetail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && param.id && param.id > 0) {
          this.get_line_items(param);
        }
      },
    },
    itemSet: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.selected_line_items = this.vSelectedLineItem;
          this.selected_line_items.map((row, index) => {
            this.selected_line_items[index].discount_type =
              this.lodash.toSafeInteger(row.discount_type);
            this.selected_line_items[index].cimages = row.item_images;
          });
          (this.show_price = param.show_price == 1 ? true : false),
            (this.adjustment = param.adjustment);
          this.gst_value =
            param.tax_value > 0
              ? param.tax_value
              : this.currentEntityDb.gstvalue;
          this.tax_active = param.tax_applied == 1 ? true : false;
          this.discount_level =
            param.discount_type == 1 ? "transaction" : "line_item";
          if (param && param.discount_value_type) {
            this.discount_value = param.discount_value;
            this.discount_type = this.lodash.toSafeInteger(
              param.discount_value_type
            );
          }
          this.calculate_total();
        }
      },
    },
    isCollected: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.show_collected = true;
        } else {
          this.show_collected = false;
        }
      },
    },
    /*  warrantyObject: {
          deep: true,
          immediate: true,
          handler(param) {
            console.log(param, "paramparamparam");
        
          },
        }, */
  },
  methods: {
    savedata(data) {
      this.selected_line_items[data.index].serialNumber = data.data;
      /*    this.localEntity = data.data; */
      this.$store.dispatch(SET_VISIT_LINE_ITEM, this.selected_line_items);
      this.seleceDialog = false;
    },
    optionSerial(row, index) {
      this.transactionDetail = row;
      this.numbersArr = row?.serial_numbers_input;
      this.numberIndex = index;
      this.seleceDialog = true;
    },
    checkAvailableQuantity(row) {
      if (row.product_type == "goods" || row.product_type == "parts") {
        if (Number(row.quantity) > Number(row.initial_stock)) {
          ErrorEventBus.$emit(
            "update:error",
            "Quantity exceeds available quantity"
          );
          return (row.quantity = row.initial_stock);
        }
      }
    },
    selectImage(index) {
      this.customIndex = index;
      this.$refs[`line-item-image`].$refs["input"].click();
    },
    async updateProfileImage(param) {
      if (param && param.length) {
        const promises = [];
        for (let i = 0; i < param.length; i++) {
          const image = await this.convertImageToBase64(param[i]);
          promises.push(image);
        }
        const lineItems = this.lodash.cloneDeep(this.selected_line_items);
        const _cimage = lineItems[this.customIndex].cimages
          ? lineItems[this.customIndex].cimages
          : [];
        lineItems[this.customIndex].cimages = [..._cimage, ...promises];
        this.selected_line_items = lineItems;
        this.$nextTick(() => {
          this.emit_values();
        });
        this.$refs[`line-item-image`].$refs["input"].value = null;
        this.imageSrc = [];
      }
    },
    convertImageToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageSrc.push(reader.result);
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    removeImage(index, pIndex) {
      this.selected_line_items[pIndex].cimages.splice(index, 1);
    },
    routeToDetailLink(id, type) {
      this.$router.push({
        name: type,
        params: { id },
      });
    },
    auto_adjust() {
      let total = toBtxNumber(this.total);
      if (total > 0) {
        let adjustment = toBtxFixed(total / 10) * 10 - total;
        this.adjustment = toBtxFixed(adjustment);
        this.$nextTick(() => {
          this.calculate_total();
        });
      }
    },
    routeToDetail(data) {
      let routeName = null;
      if (data.product_type == "equipment") {
        routeName = "client-equipment.detail";
      } else if (data.product_type == "tools") {
        routeName = "equipment.detail";
      } else if (data.product_type == "goods") {
        routeName = "product.detail";
      } else if (data.product_type == "service") {
        routeName = "service.detail";
      } else if (data.product_type == "parts") {
        routeName = "part.detail";
      }
      if (data && data.product_id && data.product_id > 0) {
        this.$router.replace(
          this.getDefaultRoute(routeName, {
            params: { id: data.product_id },
          })
        );
      }
    },
    get_col_style(param, child) {
      let width = 46;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 46;
          if (this.discount_level == "transaction") {
            width = 70;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
    calculate_total() {
      let d_type = this.discount_level;

      this.subtotal = 0;
      this.discount = 0;
      this.gst = 0;
      this.gst_amount = 0;
      this.total_non_text_amount = 0;

      for (let i = 0; i < this.selected_line_items.length; i++) {
        let total = this.accountingToFixed(
          toBtxNumber(this.selected_line_items[i].quantity) *
            toBtxNumber(this.selected_line_items[i].rate)
        );
        let discount = 0;

        if (d_type == "line_item") {
          if (
            this.lodash.toSafeInteger(
              this.selected_line_items[i].discount_type
            ) == 1
          ) {
            discount = toBtxNumber(this.selected_line_items[i].discount_value);
          }
          if (
            this.lodash.toSafeInteger(
              this.selected_line_items[i].discount_type
            ) == 2
          ) {
            discount = this.accountingToFixed(
              (toBtxNumber(total) *
                toBtxNumber(this.selected_line_items[i].discount_value)) /
                100
            );
          }
        }
        this.selected_line_items[i].total =
          isNaN(total) || !total
            ? 0
            : toBtxNumber(total) - toBtxNumber(discount);
        this.subtotal = this.accountingToFixed(
          toBtxNumber(this.subtotal) +
            toBtxNumber(this.selected_line_items[i].total)
        );
      }

      let subtotal = this.subtotal;

      if (d_type == "transaction") {
        this.discount = 0;
        if (this.discount_type == 1) {
          this.discount = toBtxNumber(this.discount_value);
        }
        if (this.discount_type == 2) {
          this.discount = this.accountingToFixed(
            (toBtxNumber(subtotal) * toBtxNumber(this.discount_value)) / 100
          );
        }
      }

      subtotal = toBtxNumber(subtotal) - toBtxNumber(this.discount);

      this.total_non_text_amount = subtotal;
      if (this.tax_active) {
        this.gst_amount = subtotal;
        this.gst = this.accountingToFixed(
          (toBtxNumber(subtotal) * toBtxNumber(this.gst_value)) / 100
        );
      }

      subtotal = toBtxNumber(subtotal) + toBtxNumber(this.gst);

      this.total = this.accountingToFixed(
        toBtxNumber(subtotal) + toBtxNumber(this.adjustment)
      );

      this.collectedAmountEmit();
      this.emit_values();
      if (this.relatedType == 3) {
        this.generateInvoiceSchedule();
      }
      if (this.relatedType == 1 && !this.isEdit) {
        this.generateInvoiceScheduleQuote();
        /* this.$store.commit(UPDATE_SCHEDULE_STATE, {
          key: "isEdit",
          value: false,
        }); */
      }
    },
    collectedAmountEmit() {
      this.collected_amount = this.total;
    },
    update_line_item(param) {
      if (this.append_child) {
        let child_index = toBtxNumber(this.child_index);
        for (let item of param) {
          item.is_child = true;
          child_index = child_index + 1;
          this.selected_line_items = this.arrayInsert(
            this.selected_line_items,
            child_index,
            item
          );
        }
      } else {
        for (let item of param) {
          this.selected_line_items.push(item);
        }
      }

      this.calculate_total();

      this.$nextTick(() => {
        this.emit_values();
        this.product_dialog = false;
        this.append_child = false;
        this.product_type = null;
        this.child_index = null;
      });
    },
    remove_property(index) {
      this.selected_line_items[index].property = new Object();

      this.$nextTick(() => {
        this.emit_values();
      });
    },
    checkData() {
      if (this.discount_type == 2 && this.discount_value) {
        if (this.discount_value > 100) {
          this.discount_value = 100;
        }
      }
    },
    changeEquipment(index, row) {
      this.selected_line_items[index].is_equipment = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    changeDes(index, row) {
      this.selected_line_items[index].description = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    changeName(index, row) {
      this.selected_line_items[index].product = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    OpenchangeWarrnty(index) {
      this.eIndex = index;
      this.addWarrantyDialog = true;
    },
    generateInvoiceSchedule() {
      let invoiceArray = [];
      invoiceArray = this.rawScheduleOutput.map((item) => ({
        daysOfWeek: item?.daysOfWeek,
        startDate: item?.startDate,
        dueDate: this.dueDate(item?.startDate),
        billing_amount: this.total,
        status: 1,
        isEdited: 0,
        isDisabled: 0,
        isPrimary: 0,
        uuid: item?.uuid,
      }));
      this.$store.commit(UPDATE_SCHEDULE_LIST, {
        key: "dbInvoiceVisitScheduleOutput",
        value: invoiceArray,
      });
    },
    dueDate(date) {
      let day = 15;
      if (this.recurringinvoice?.payment_due == 2) {
        day = 15;
      } else if (this.recurringinvoice?.payment_due == 3) {
        day = 30;
      } else if (this.recurringinvoice?.payment_due == 4) {
        day = 45;
      }
      let dueData = moment(date).add(day, "days").format("YYYY-MM-DD");
      return dueData;
    },
    generateInvoiceScheduleQuote() {
      /*  let invoiceArray = []; */
      let invoiceArrayTotelAmt = [];
      let total = 0;
      if (this.quoteInvoiceParameters?.invoice_amount_type == "total_amount") {
        total = this.total_non_text_amount;
      } else {
        total =
          this.total_non_text_amount /
          Number(this.invoiceRecurringSchedule?.occurrence);
      }
      invoiceArrayTotelAmt = this.invoiceScheduleOutput?.map((item) => ({
        daysOfWeek: item?.daysOfWeek,
        startDate: item?.startDate,
        dueDate: this.dueDateQue(item?.startDate),
        billing_amount: total,
        status: 1,
        isEdited: 0,
        isDisabled: 0,
        isPrimary: 0,
        otherItem: item?.otherItem ?? [],
        toatalAmt: item?.toatalAmt ?? 0,
      }));
      this.$store.commit(UPDATE_SCHEDULE_LIST_QUOTE, {
        key: "dbScheduleOutput",
        value: invoiceArrayTotelAmt,
      });
    },
    dueDateQue(date) {
      let day = 15;
      if (this.quoteInvoiceParameters?.payment_due == 2) {
        day = 15;
      } else if (this.quoteInvoiceParameters?.payment_due == 3) {
        day = 30;
      } else if (this.quoteInvoiceParameters?.payment_due == 4) {
        day = 45;
      }
      let dueData = moment(date).add(day, "days").format("YYYY-MM-DD");
      return dueData;
    },
    warrantyData(data) {
      this.selected_line_items[this.eIndex].equipment_warranty = 0;
      this.selected_line_items[this.eIndex].warranty_cost = data.cost;
      this.selected_line_items[this.eIndex].warranty_description =
        data.description;
      this.selected_line_items[this.eIndex].warranty_duration = data.duration;
      this.selected_line_items[this.eIndex].warranty_duration_type =
        data.duration_type;
      this.selected_line_items[this.eIndex].warranty_end_date = data.end_date;
      this.selected_line_items[this.eIndex].warranty_start_date =
        data.start_date;
      this.selected_line_items[this.eIndex].warranty_unique_id = data.unique_id;
      this.selected_line_items[this.eIndex].warranty_type = data.warranty_type;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    changeWarrnty(index, row) {
      this.selected_line_items[index].equipment_warranty =
        row.equipment_warranty;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    update_child_property(row) {
      this.selected_line_items[this.child_index].property = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    add_child(index, type) {
      this.append_child = true;
      this.dialog_key = Number(new Date());
      this.child_index = index;
      if (type == "property" || type == "equipment" || type == "tools") {
        if (!this.vCustomerId && !type == "tools") {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "equipment") {
          this.product_type = type;
          this.product_dialog = true;
        } else if (type == "tools") {
          this.product_type = type;
          this.product_dialog = true;

          /*     this.property_dialog = true; */
        } else {
          this.property_dialog = true;
        }
      }
      if (type == "product" || type == "parts") {
        this.product_type = type;
        this.product_dialog = true;
      }

      if (type == "service") {
        this.product_type = type;
        this.product_dialog = true;
      }
    },
    emit_values() {
      this.$nextTick(() => {
        this.$store.dispatch(SET_VISIT_CALCULATION, {
          discount_type: this.lodash.toSafeInteger(this.discount_type),
          discount_value: this.discount_value,
          tax_active: this.tax_active,
          adjustment: this.adjustment,
          collected_amount: this.collected_amount,
          visit_amount: this.total,
          show_price: this.show_price,
          discount_level: this.discount_level,
          calculate_total: this.calculate_total,
          tax_value: this.gst_value,
          total_non_text_amount: this.total_non_text_amount,
        });
        this.$store.dispatch(SET_VISIT_LINE_ITEM, this.selected_line_items);
      });
    },

    open_dialog(param) {
      if (!param) {
        return false;
      }
      if (param == "equipment") {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
      }
      this.dialog_key = Number(new Date());
      this.product_type = param;
      this.product_dialog = true;
    },
    add_header() {
      let len = this.selected_line_items.length + 1;
      this.selected_line_items.push({
        status: true,
        index: uuidv4(),
        uuid: uuidv4(),
        id: 0,
        order: len,
        type: "header",
        product_type: "header",
        is_child: false,
        has_child: false,
        has_property: false,
        product: null,
      });
      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    add_other() {
      let len = this.selected_line_items.length + 1;
      this.selected_line_items.push({
        status: true,
        index: uuidv4(),
        uuid: uuidv4(),
        id: null,
        image: {},
        order: len,
        serial_no: null,
        eq_model: null,
        location: null,
        group: null,
        discount: 0,
        type: "other",
        is_child: false,
        parent_uuid: null,
        has_child: true,
        has_property: true,
        property: {},
        group_primary: false,
        discount_value: 0,
        discount_type: 1,
        to_equipment: 0,
        product: null,
        project_price: 0,
        threshold_price: 0,
        product_id: 0,
        product_type: "other",
        description: null,
        has_warranty: false,
        warranty: {},
        rate: 0,
        selling_cost: 0,
        quantity: 1,
        uom: null,
        total: 0,
      });
      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    get_line_items(param) {
      let id = param.id;
      this.loading = true;
      this.$store
        .dispatch(QUERY, {
          url: `visit-v1/${id}/line-items`,
          data: {
            type: this.relatedType,
          },
        })
        .then(({ data }) => {
          this.selected_line_items = data;
          this.selected_line_items.map((row, index) => {
            this.selected_line_items[index].discount_type =
              this.lodash.toSafeInteger(row.discount_type);
            this.selected_line_items[index].cimages = row.item_images;
          });
          (this.show_price = param.show_price == 1 ? true : false),
            (this.adjustment = param.adjustment);
          this.collected_amount = param.collected_amount;
          this.gst_value =
            param.tax_value > 0
              ? param.tax_value
              : this.currentEntityDb.gstvalue;
          this.tax_active = param.tax_applied == 1 ? true : false;
          this.discount_level =
            param.discount_type == 1 ? "transaction" : "line_item";
          if (param && param.discount_value_type) {
            this.discount_value = param.discount_value;
            this.discount_type = this.lodash.toSafeInteger(
              param.discount_value_type
            );
          }
          this.calculate_total();
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove_row(index, row) {
      if (row.preventive_id > 0 && row.id > 0 && row?.is_consumed > 0) {
        this.$emit("remove:payload", row);
      }

      this.selected_line_items.splice(index, 1);
      this.calculate_total();
      this.emit_values();
      if (this.relatedType == 3) {
        this.generateInvoiceSchedule();
      }
    },
    isGSTAllowed() {
      const app_config = AppConfiguration.get();
      const { entity } = app_config;
      const currentEntity = find(entity, { id: this.entityId });
      this.currentEntityDb = currentEntity;
      this.gst_value = currentEntity.gstvalue > 0 ? currentEntity.gstvalue : 9;

      if (currentEntity) {
        if (currentEntity.gst) {
          if (
            this.isJob ||
            this.isEnquiry ||
            this.isQuotation ||
            this.isPreventive
          ) {
            this.tax_active = true;
            return true;
          } else {
            this.tax_active = false;
          }
        }
      }
      return true;
    },
    openSchedule(id) {
      this.pmId = id;
      this.scheduleDialog = true;
    },
  },
  components: {
    Draggable,
    LineItemDialog,
    AddressSelect,
    ManageWarranty,
    PMSchedule,
    SerialNumber,
  },
  mounted() {
    this.do_update = this.lodash.toSafeInteger(this.$route.query.do_update);

    this.isGSTAllowed();
  },
  beforeMount() {
    this.pmData = this.$route?.query?.pmSchedule;
  },
  created() {
    const relatedId = Number(this.$route?.query?.edit ?? 0);
    if (relatedId > 0) {
      this.$store.commit(UPDATE_SCHEDULE_STATE, {
        key: "isEdit",
        value: true,
      });
      this.$store.commit(UPDATE_SCHEDULE_LIST_QUOTE, {
        key: "dbScheduleOutput",
        value: this.dbInvoiceUpdateSchedule,
      });
    }
  },
  computed: {
    ...mapGetters([
      "vCustomerId",
      "vStateLoading",
      "vLineItem",
      "vSelectedLineItem",
      "vLineItemAlpha",
      "vLineItemCategory",
      "rawScheduleOutput",
      "recurringinvoice",
      "invoiceScheduleOutput",
      "invoiceRecurringSchedule",
      "quoteInvoiceParameters",
      "dbInvoiceUpdateSchedule",
      "isEdit",
    ]),
  },
};
</script>
