<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          {{ preventiveArr?.preventive?.barcode }} ({{
            preventiveArr?.pmSchedule?.length
          }})
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 100vh; position: relative"
          >
            <v-form
              ref="scheduleForm"
              v-model.trim="scheduleForm"
              lazy-validation
            >
              <v-row>
                <v-col md="12" style="margin-top: -20px !important">
                  <v-simple-table class="inner-simple-table" fixed-header>
                    <template v-slot:default>
                      <thead
                        style="
                          background-color: rgb(243, 246, 249);
                          color: rgb(36, 50, 109);
                        "
                      >
                        <tr class="custom-border-bottom">
                          <td class="font-size-16 font-weight-700">
                            Schedule #
                          </td>
                          <td class="font-size-16 font-weight-700">Date</td>
                          <td class="font-size-16 font-weight-700">PM Done</td>
                          <td class="font-size-16 font-weight-700">
                            No. Of Equipment Serviced
                          </td>
                          <td class="font-size-16 font-weight-700" width="40%">
                            Visits
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-if="
                            lodash.isEmpty(preventiveArr?.pmSchedule) === false
                          "
                        >
                          <tr
                            v-for="(data, index) in preventiveArr?.pmSchedule"
                            :key="index"
                            :class="{ 'orange_lighten-5': index % 2 == 0 }"
                          >
                            <td class="py-2 font-weight-500">
                              <v-chip
                                class="mr-2 font-weight-600"
                                color="chip-custom-blue"
                                label
                                small
                                outlined
                              >
                                {{ data?.barcode }}
                              </v-chip>
                            </td>
                            <td class="py-2 font-weight-500">
                              {{ formatDate(data.startDate) }}
                            </td>

                            <td class="py-2">
                              <span class="font-weight-500">
                                <v-icon
                                  v-if="data.pm_done"
                                  size="20"
                                  color="green"
                                >
                                  mdi-check-all</v-icon
                                >
                                <em class="text-muted" v-else> No</em>
                              </span>
                            </td>
                            <td class="py-2">
                              <span
                                class="font-weight-600"
                                style="font-size: 15px"
                              >
                                <span class="green--text"
                                  ><template v-if="data.items_count">
                                    {{ data.items_count }}</template
                                  >
                                  <template v-else> 0</template>
                                </span>
                                /
                                <span class="red--text">{{
                                  preventiveArr?.pmItems?.length
                                }}</span>
                              </span>
                            </td>
                            <td class="py-2" width="40%">
                              <span
                                class="font-weight-700"
                                v-if="
                                  data &&
                                  data.schedule_visit &&
                                  data?.schedule_visit?.length > 0
                                "
                              >
                                <template
                                  v-for="(row, index) in data?.schedule_visit"
                                >
                                  <v-chip
                                    :key="'ticket-type' + index"
                                    class="mr-2 font-weight-600"
                                    color="chip-custom-blue"
                                    label
                                    small
                                    outlined
                                  >
                                    {{ row.visit.barcode }}
                                  </v-chip>
                                </template>
                              </span>
                              <em v-else class="text-muted"> no visit </em>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr>
                            <td :colspan="6">
                              <p
                                class="m-0 row-not-found text-center font-weight-500 font-size-16"
                              >
                                Sorry! No Schedule(s) Found.
                              </p>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-form>
          </perfect-scrollbar>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              depressed
              :disabled="scheduleLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="$emit('close:dialog')"
              >Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
import { GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "create-or-update-warranty",
  mixins: [CommonMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  watch: {
    dialogStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.openSchedule(this.parentId);
        }
      },
    },
  },
  data() {
    return {
      scheduleTab: "schedule",
      formValid: true,
      pageLoading: false,
      scheduleLoading: false,
      preventiveArr: {},
      scheduleForm: true,
    };
  },
  methods: {
    openSchedule(id) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "preventive-schedule/" + id,
        })
        .then(({ data }) => {
          _this.preventiveArr = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  /* mounted(){
      this.selectedAll = true;
    } */
};
</script>
