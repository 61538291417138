import { render, staticRenderFns } from "./Serial-Number-Select.vue?vue&type=template&id=705ff772&scoped=true"
import script from "./Serial-Number-Select.vue?vue&type=script&lang=js"
export * from "./Serial-Number-Select.vue?vue&type=script&lang=js"
import style0 from "./Serial-Number-Select.vue?vue&type=style&index=0&id=705ff772&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705ff772",
  null
  
)

export default component.exports