var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"origin":"top center","persistent":"","content-class":"warranty-dialog","max-width":_vm.dialogWidth},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"},[_vm._v(" "+_vm._s(_vm.preventiveArr?.preventive?.barcode)+" ("+_vm._s(_vm.preventiveArr?.pmSchedule?.length)+") ")]),_c('v-card-text',{staticClass:"p-8 font-size-16"},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"100vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-form',{ref:"scheduleForm",attrs:{"lazy-validation":""},model:{value:(_vm.scheduleForm),callback:function ($$v) {_vm.scheduleForm=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"scheduleForm"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"-20px !important"},attrs:{"md":"12"}},[_c('v-simple-table',{staticClass:"inner-simple-table",attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticStyle:{"background-color":"rgb(243, 246, 249)","color":"rgb(36, 50, 109)"}},[_c('tr',{staticClass:"custom-border-bottom"},[_c('td',{staticClass:"font-size-16 font-weight-700"},[_vm._v(" Schedule # ")]),_c('td',{staticClass:"font-size-16 font-weight-700"},[_vm._v("Date")]),_c('td',{staticClass:"font-size-16 font-weight-700"},[_vm._v("PM Done")]),_c('td',{staticClass:"font-size-16 font-weight-700"},[_vm._v(" No. Of Equipment Serviced ")]),_c('td',{staticClass:"font-size-16 font-weight-700",attrs:{"width":"40%"}},[_vm._v(" Visits ")])])]),_c('tbody',[(
                          _vm.lodash.isEmpty(_vm.preventiveArr?.pmSchedule) === false
                        )?_vm._l((_vm.preventiveArr?.pmSchedule),function(data,index){return _c('tr',{key:index,class:{ 'orange_lighten-5': index % 2 == 0 }},[_c('td',{staticClass:"py-2 font-weight-500"},[_c('v-chip',{staticClass:"mr-2 font-weight-600",attrs:{"color":"chip-custom-blue","label":"","small":"","outlined":""}},[_vm._v(" "+_vm._s(data?.barcode)+" ")])],1),_c('td',{staticClass:"py-2 font-weight-500"},[_vm._v(" "+_vm._s(_vm.formatDate(data.startDate))+" ")]),_c('td',{staticClass:"py-2"},[_c('span',{staticClass:"font-weight-500"},[(data.pm_done)?_c('v-icon',{attrs:{"size":"20","color":"green"}},[_vm._v(" mdi-check-all")]):_c('em',{staticClass:"text-muted"},[_vm._v(" No")])],1)]),_c('td',{staticClass:"py-2"},[_c('span',{staticClass:"font-weight-600",staticStyle:{"font-size":"15px"}},[_c('span',{staticClass:"green--text"},[(data.items_count)?[_vm._v(" "+_vm._s(data.items_count))]:[_vm._v(" 0")]],2),_vm._v(" / "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.preventiveArr?.pmItems?.length))])])]),_c('td',{staticClass:"py-2",attrs:{"width":"40%"}},[(
                                data &&
                                data.schedule_visit &&
                                data?.schedule_visit?.length > 0
                              )?_c('span',{staticClass:"font-weight-700"},[_vm._l((data?.schedule_visit),function(row,index){return [_c('v-chip',{key:'ticket-type' + index,staticClass:"mr-2 font-weight-600",attrs:{"color":"chip-custom-blue","label":"","small":"","outlined":""}},[_vm._v(" "+_vm._s(row.visit.barcode)+" ")])]})],2):_c('em',{staticClass:"text-muted"},[_vm._v(" no visit ")])])])}):[_c('tr',[_c('td',{attrs:{"colspan":6}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_vm._v(" Sorry! No Schedule(s) Found. ")])])])]],2)]},proxy:true}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"my-0"}),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"warrant-manage-action"},[_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"depressed":"","disabled":_vm.scheduleLoading},on:{"click":function($event){return _vm.$emit('close:dialog')}}},[_vm._v("Close ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }