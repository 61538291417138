<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          Select Serial : {{ transactionDetail?.product }} ({{
            transactionDetail?.barcode
          }})
          <v-spacer></v-spacer>
          <div>
            <v-text-field
              style="max-width: 300px"
              v-model.trim="search"
              v-on:click:clear="(search = null), get_numbers()"
              clearable
              dense
              filled
              flat
              placeholder="Search"
              hide-details
              solo
              color="cyan"
              @keydown.enter="get_numbers()"
              @keydown.tab="get_numbers()"
            ></v-text-field>
          </div>
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 70vh; position: relative"
          >
            <v-form
              ref="serialFormRff"
              v-model.trim="serialFormRff"
              lazy-validation
              v-on:submit.stop.prevent="onDetailSubmit()"
            >
              <v-row class="p-0" style="border-bottom: 1px solid lightgray">
                <v-col
                  md="9"
                  style="
                    margin-bottom: -1px !important;
                    margin-top: -10px !important;
                  "
                >
                  <label
                    for=""
                    class="font-weight-600 m-0"
                    style="font-size: 18px; color: #24326d !important"
                  >
                    Total Available : {{ serialNumber?.length }}</label
                  >
                </v-col>
                <v-col
                  md="3"
                  style="
                    margin-bottom: -1px !important;
                    margin-top: -10px !important;
                  "
                >
                  <label
                    for=""
                    class="font-weight-600 m-0"
                    style="font-size: 18px; color: #24326d !important"
                  >
                    Quantity : {{ enities?.length ?? 0 }} /
                    {{ transactionDetail.quantity }}</label
                  >
                </v-col>
              </v-row>
              <v-row v-if="serialNumber?.length > 0">
                <v-col
                  lg="2"
                  md="2"
                  v-for="(row, rowindex) in serialNumber"
                  :key="rowindex"
                  sty
                >
                  <div class="d-flex">
                    <v-chip-group multiple active-class="">
                      <v-chip
                        v-on:click="selecteData(row)"
                        :class="{ customChipChild: enities?.includes(row.id) }"
                        label
                      >
                        {{ row.text }}
                      </v-chip>
                    </v-chip-group>
                    <div class="mt-3">
                      <showBarcode
                        :barcodeUrl="row.barcode_image"
                        title="Barcode"
                        :barcode-text="row.text"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-content-center mt-2 mb-2" v-else>
                <div>
                  <p class="m-0 row-not-found font-weight-500 font-size-16">
                    <img
                      height="30"
                      width="35"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no serial number at the moment.
                  </p>
                </div>
              </v-row>
            </v-form>
          </perfect-scrollbar>
          <v-divider class="my-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="warrant-manage-action">
              <v-btn
                depressed
                :disabled="!serialFormRff || serialLoading"
                :loading="serialLoading"
                class="mx-2 custom-bold-button white--text"
                v-on:click="onDetailSubmit()"
                color="cyan"
                >Save
              </v-btn>
              <v-btn
                depressed
                :disabled="serialLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="$emit('close:dialog')"
                >Close
              </v-btn>
            </div>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}

.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import showBarcode from "@/view/components/showBarcode.vue";
import { mapGetters } from "vuex";

import {
  ErrorEventBus,
  InitializeError,
  SuccessEventBus,
} from "@/core/lib/message.lib";
/*   import moment from "moment"; */

export default {
  name: "create-or-update-warranty",
  mixins: [CommonMixin],
  props: {
    type: {
      type: String,
      default: null,
    },
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    /* localEntity: {
      type: Array,
      default() {
        return [];
      },
    }, */
    /*   serialNumber: {
            type: Array,
            default() {
                return [];
            },
        }, */
    transactionDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentId: {
      type: Number,
      default: 0,
    },
    parentIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    dialogStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.get_numbers();
        }
      },
    },
  },
  data() {
    return {
      search: null,
      formValid: true,
      serialFormRff: true,
      pageLoading: false,
      serialLoading: false,
      preventiveArr: {},
      serialNumber: [],
      enities: [],
      preArray: [],
      isCheck: false,
    };
  },
  methods: {
    sortArray() {
      this.preArray = this.serialNumber;
      const orderMap = new Map(this.enities?.map((id, index) => [id, index]));

      // Separate the items that are in the order array and those that are not
      const orderedItems = this.preArray.filter((item) =>
        orderMap.has(item.id)
      );
      const unorderedItems = this.preArray.filter(
        (item) => !orderMap.has(item.id)
      );

      // Sort the ordered items based on the order array
      orderedItems.sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id));

      // Combine the sorted ordered items with the unordered items
      this.serialNumber = [...orderedItems, ...unorderedItems];
    },
    copyNumber(data) {
      navigator.clipboard.writeText(data);
      SuccessEventBus.$emit("update:success", data + " successfully coppied.");
    },
    onDetailSubmit() {
      if (
        Number(this.enities?.length) > Number(this.transactionDetail.quantity)
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Ensure that the quantity entered matches the Serial Number count"
          )
        );
        return false;
      }
      let payload = {
        data: this.enities,
        parent: this.transactionDetail.product_id,
        index: this.parentIndex,
      };
      this.$emit("update:item", payload);
    },
    get_numbers() {
      this.loading = true;
      this.$store
        .dispatch(QUERY, {
          url: `serial-number/${this.transactionDetail.product_id}`,
          data: {
            search: this.search,
          },
        })
        .then((response) => {
          this.serialNumber = response?.data.rows;
          if (this.$route?.query?.edit) {
            this.enities = this.transactionDetail.serialize_number;
            this.sortArray();
          } else {
            this.enities =
              this.vSelectedLineItem[this.parentIndex]?.serialNumber;
            this.sortArray();
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selecteData(row) {
      if (row) {
        if (!this.enities) {
          this.enities = [];
        }

        const foundIndex = this.enities?.findIndex((item) => item === row?.id);

        if (foundIndex !== -1) {
          this.enities?.splice(foundIndex, 1);
        } else {
          this.enities?.push(row.id);
        }
      }
      this.sortArray();
    },
  },

  computed: {
    ...mapGetters(["vSelectedLineItem"]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
  components: {
    showBarcode,
  },
};
</script>
