<template>
  <v-menu
    v-model="datepicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        filled
        flat
        :id="id"
        :placeholder="placeholder"
        hide-details
        solo
        :disabled="disabled"
        prepend-inner-icon="mdi-calendar"
        readonly
        :rules="rules"
        v-bind="attrs"
        v-on="on"
        :value="formattedDate"
        color="cyan"
        :class="className"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      color="cyan"
      :min="minDate"
      :max="maxDate"
      no-title
      :first-day-of-week="weekday"
      :disabled="disabled"
      v-on:input="datepicker = false"
      v-on:change="emitValue()"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/* import AppConfiguration from "@/core/config/app.config"; */
import moment from "moment-timezone";
import { GET } from "@/core/services/store/request.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "datepicker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    id: {
      type: String,
      default: "start-date",
    },
    placeholder: {
      type: String,
      default: "Start Date",
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: function () {
      this.init();
    },
  },
  data() {
    return {
      datepicker: false,
      date: null,
      AppConfiguration: {},
      timeFormat: null,
      dateTimeFormat: null,
      dateFormat: null,
    };
  },
  methods: {
    init() {
      this.date = this.value;
    },

    emitValue() {
      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
    getAppConfig() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "configuration" })
        .then(({ data }) => {
          (this.AppConfiguration = data),
            (this.dateFormat = data.date_format),
            (this.dateTimeFormat = data.date_format + " " + data.time_format),
            (this.timeFormat = data.time_format);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.init();
    this.getAppConfig();
  },
  computed: {
    /*   formattedDate() {
      let Config = AppConfiguration.get();
      if (Config && this.date) {
        return moment(this.date).format(Config.dateFormat);
      }
      return this.date;
    },
    weekday() {
      let Config = AppConfiguration.get();
      if (Config && Config.weekday == 1) {
        return 0;
      } else {
        return 1;
      }
    }, */
    formattedDate() {
      let Config = this.AppConfiguration;
      if (Config && this.date) {
        return moment(this.date).format(this.dateFormat);
      }
      return this.date;
    },
    weekday() {
      let Config = this.AppConfiguration;
      if (Config && Config.weekday == 1) {
        return 0;
      } else {
        return 1;
      }
    },
  },
};
</script>
